import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Image, Box, Button, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "90px 0 100px 0",
	"quarkly-title": "Price-18",
	"background": "linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/3-3.jpg?v=2024-05-30T11:46:42.490Z) 0% 0% /cover no-repeat scroll padding-box"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"text-align": "center",
			"font": "--headline2",
			"color": "--light",
			"children": "Огляд меню"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 70px 0px",
			"text-align": "center",
			"font": "--lead",
			"color": "--light",
			"width": "700px",
			"align-self": "center",
			"md-align-self": "auto",
			"max-width": "100%",
			"children": "У кав’ярні Mocha Delight ми пишаємося тим, що пропонуємо різноманітне меню, яке задовольнить усіх любителів кави."
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "0 34px",
			"lg-grid-template-columns": "1fr",
			"lg-grid-gap": "35px 0",
			"height": "auto",
			"grid-template-rows": "auto"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"background": "--color-light",
			"border-radius": "25px",
			"justify-content": "space-between"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"height": "auto"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"display": "block",
			"width": "100%",
			"height": "300px",
			"object-fit": "cover",
			"border-radius": "25px 25px 0 0",
			"src": "https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "25px 0px 25px 0px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"padding": "0px 25px 0px 25px",
			"height": "auto"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"color": "--darkL1",
			"text-align": "center",
			"font": "--lead",
			"text-transform": "uppercase",
			"children": "Кавові напої"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"text-align": "center",
			"display": "none",
			"children": "**Твори еспресо:** Наші еспресо-напої, виготовлені майстрами, є серцевиною нашого меню. Кожен шот насичений і насичений, створюючи ідеальну основу для різноманітних напоїв.\n **Класичний еспресо:** чистий і насичений еспресо.\n **Капучіно:** Збалансована суміш еспресо, пареного молока та піни.\n **Латте:** Гладкий еспресо з парним молоком і легким шаром піни.\n**Speciality Coffee:** Ці напої пропонують унікальний відтінок традиційних улюблених напоїв, ідеально підходить для тих, хто хоче спробувати щось нове.\n **Mocha Delight:** Еспресо в поєднанні з насиченим шоколадом і парним молоком.\n **Caramel Macchiato:** Чудова суміш еспресо, карамелі та молока.\n **Vanilla Latte:** М’який еспресо з відтінком ванілі."
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"margin": "0px 0px 30px 0px",
			"background": "--color-darkL1",
			"href": "/services",
			"type": "link",
			"text-decoration-line": "initial"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"background": "--color-light",
			"border-radius": "25px",
			"justify-content": "space-between"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"display": "block",
			"width": "100%",
			"height": "300px",
			"object-fit": "cover",
			"border-radius": "25px 25px 0 0",
			"object-position": "top",
			"src": "https://images.unsplash.com/photo-1571934811356-5cc061b6821f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "25px 0px 25px 0px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"padding": "0px 25px 0px 25px",
			"height": "auto"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"color": "--darkL1",
			"text-align": "center",
			"font": "--lead",
			"text-transform": "uppercase",
			"children": "Чаї та інші напої"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"text-align": "center",
			"display": "none",
			"children": "Чаї:** вибір вишуканих чаїв для тих, хто віддає перевагу чомусь іншому.\n **Зелений чай: ** Свіжий і підбадьорливий.\n **Chai Latte:** суміш пряного чаю з парним молоком.\n **Трав’яні чаї: ** Різні смаки для заспокоєння та розслаблення.\n**Варіанти без кави:** ми також пропонуємо різноманітні інші напої.\n **Гарячий шоколад:** насичений і кремовий шоколадний напій.\n **Пароварка:** Тепле молоко зі смаком на вибір.\n **Лимонад:** свіжовичавлений для терпкого та солодкого смаку."
		}
	},
	"button1": {
		"kind": "Button",
		"props": {
			"margin": "0px 0px 30px 0px",
			"background": "--color-darkL1",
			"href": "/services",
			"type": "link",
			"text-decoration-line": "initial"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"background": "--color-light",
			"border-radius": "25px",
			"justify-content": "space-between"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"display": "block",
			"width": "100%",
			"height": "300px",
			"object-fit": "cover",
			"border-radius": "25px 25px 0 0",
			"object-position": "left",
			"src": "https://images.unsplash.com/photo-1565182363525-f95648bbeee0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "25px 0px 25px 0px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"padding": "0px 25px 0px 25px",
			"height": "auto"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"color": "--darkL1",
			"text-align": "center",
			"font": "--lead",
			"text-transform": "uppercase",
			"children": "Випічка та закуски"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"text-align": "center",
			"display": "none",
			"children": "Свіжоспечена випічка:** Ідеальний гарнір до кави.\n **Круасани:** пластівчасті та маслянисті, щодня свіжі.\n **Мафіни:** М’які та ароматні, з різними варіантами.\n **Булочки:** Легкі та розсипчасті, ідеальні для ранкового частування.\n**Солоні закуски:**, коли вам потрібно щось ситніше.\n **Сандвічі:** зі свіжих інгредієнтів і різноманітними начинками.\n **Кіші:** смачні солоні пироги, ідеальні для будь-якого часу доби.\n **Салати:** свіжі та здорові страви, які доповнять ваш напій."
		}
	},
	"button2": {
		"kind": "Button",
		"props": {
			"margin": "0px 0px 30px 0px",
			"background": "--color-darkL1",
			"href": "/services",
			"type": "link",
			"text-decoration-line": "initial"
		}
	}
};

const Services = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Image {...override("image")} />
					<Box {...override("box3")}>
						<Text {...override("text2")} />
						<Text {...override("text3")} />
					</Box>
				</Box>
				<Button {...override("button")}>
					Послуги
				</Button>
			</Box>
			<Box {...override("box4")}>
				<Box {...override("box5")}>
					<Image {...override("image1")} />
					<Box {...override("box6")}>
						<Text {...override("text4")} />
						<Text {...override("text5")} />
					</Box>
					<Button {...override("button1")}>
						Послуги
					</Button>
				</Box>
			</Box>
			<Box {...override("box7")}>
				<Box {...override("box8")}>
					<Image {...override("image2")} />
					<Box {...override("box9")}>
						<Text {...override("text6")} />
						<Text {...override("text7")} />
					</Box>
					<Button {...override("button2")}>
						Послуги
					</Button>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Services, { ...Section,
	defaultProps,
	overrides
});
export default Services;